<template>
  <div class="rank-report-chart">
    <div class="chart-title" v-if="title.length > 0">{{ title }}</div>
    <vue-highcharts
      v-if="chartSeries.length > 0"
      type="chart"
      :options="preparedChartData"
      :redrawOnUpdate="true"
      :oneToOneUpdate="false"
      :animateOnUpdate="true"/>
  </div>
</template>

<script>
import {shortenDigits, getFlagSpan} from "@/utils/utils";

export default {
  name: 'ImpressionsChart',
  props: {
    chartData: Array,
    title: String,
  },
  computed: {
    xAxisCategories() {
      if (this.chartData.length === 0) {
        return [];
      } else {
        return Object.keys(this.chartData[0]['data']);
      }
    },
    chartSeries() {
      let series = [];
      this.chartData.forEach((item) => {
        let it = {
          name: item.title,
          data: Object.values(item.data),
          countryCode: item.country_code,
        };
        if (item.title === 'Other') {
          it.color = '#E1E8F2';
        }
        series.push(it);
      });

      return series;
    },
    preparedChartData() {
      return {
        chart: {
          type: 'column'
        },
        title: false,
        xAxis: {
          categories: this.xAxisCategories,
        },
        colors: [
          '#b2eca3',
          '#1B8DDF',
          '#71ecc2',
          '#C053F3',
          '#1BBCDF',
          '#ee548f',
          '#81e174',
          '#EDDA30',
          '#F053F3',
          '#4a64e3',
        ],
        yAxis: [
          {
            title: {
              text: 'Impressions'
            },
          },
          {
            linkedTo: 0,
            opposite: true,
            title: false,
          }
        ],
        legend: {
          align: 'right',
          verticalAlign: 'top',
          layout: 'vertical',
          x: 0,
          y: 50,
          itemMarginBottom: 10,
          itemStyle: {
            fontWeight: 'normal',
          },
          symbolRadius: 1,
        },
        tooltip: {
          useHTML: true,
          shadow: false,
          backgroundColor: "#fff",
          borderColor: '#E1E8F2',
          shared: true,
          formatter() {
            let html = `<div class="rank-report-chart-tooltip">`;
            html += `<div class="title">${this.x}</div>`;
            html += `<table>`;
            this.points.forEach((point) => {
              html += `<tr>`;
              html += `<td><div class="circle" style="background-color: ${point.color};"></div></td>`;
              let flag = '';
              if (point.series.name !== 'Other' && point.series.userOptions.countryCode !== undefined) {
                flag = getFlagSpan(point.series.userOptions.countryCode);
              }
              html += `<td class="row-title">${flag}<div>${point.series.name}</div></td>`;
              let y = shortenDigits(point.y);
              html += `<td>${y}</td>`;
              html += `</tr>`;
            });
            html += `</table>`;
            html += `</div>`;

            return html;
          },
        },
        plotOptions: {
          column: {
            stacking: 'normal',
          }
        },
        series: this.chartSeries,
      };
    },
  },
}
</script>
